defineDs('DanskeSpil/Domain/Authentification/Scripts/LoginController', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Domain/Authentification/Scripts/KeepSessionAliveTimer',
  'DanskeSpil/Domain/Authentification/Scripts/LoginCache',
  'DanskeSpil/Domain/Authentification/Scripts/LoginAdapter',
  'DanskeSpil/Domain/Authentification/Scripts/LoginSSO',
  'DanskeSpil/Domain/Authentification/Scripts/UINewLogin',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'Common/Framework/EventHandling/Scripts/CrossWindowEvents',
  'DanskeSpil/Domain/Header/Scripts/HeaderNotifications',
  'DanskeSpil/Project/Design/Js/Global/HashChange',
  'Common/Framework/ErrorHandling/Scripts/ErrorLogging'
], function (Ensighten, Utils, Event, KeepSessionAliveTimer, LoginCache, LoginAdapter, LoginSSO, UINewLogin, SitecoreAuth, CrossWindowEvents, HeaderNotifications, HashChange, ErrorLogging) {

  var _synced = false;

  var isSynced = function () {
    return _synced;
  };


  /** *******************************************************************
   * Open login overlay.
   * Supported options:
   *   loginSuccessUrl
   *   cancelUrl
   *   source
   *   variant
   *
   * Not supported anymore:
   *  widgetToLoad: 'nemid-login' or 'username-password-login'
   *  onCancel: function invoked when login is cancelled
   *  afterLoggedIn: function invoked after successful login
   *  redirect
   */
  var openLogin = function (options) {
    options = options || {};

    if (!options.cancelUrl) {
      // If eg account page is called with cancelUrl, pass this to the loginpage
      var cancelUrl = Utils.getParameter('cancelUrl');
      if (cancelUrl) {
        options.cancelUrl = cancelUrl;
      }
    }

    // From UINewLogin.showLoginForm:
    if (location.hash === '#login') {
      // Strip #login away, so we wont get back to that if user cancels login
      try {
        history.replaceState(null, document.title, location.href.split('#')[0]);
      } catch (e) {
      }
    }

    var overrideReturnUrl = document.body.getAttribute('data-override-login-return-url');
    var returnUrl = overrideReturnUrl || options.loginSuccessUrl || location.href;
    var url = DS.Config.BASE_HOST;

    // Host prefix (for Dev env purpose, see IU-17292)
    if (DS.Config.LOGINPAGE_HOST && DS.Config.LOGINPAGE_HOST.indexOf('https://') === 0) {
      url = DS.Config.LOGINPAGE_HOST;

      if (returnUrl.indexOf('/') === 0) {
        returnUrl = location.protocol + '//' + location.host + returnUrl;
      }
    }

    if (options.source) {
      returnUrl = Utils.appendParameter('source', options.source, returnUrl);
    }

    if (document.body.classList.contains('RetailAccountFrontPage') || document.body.classList.contains('RetailAccountContentPage')) {
      url = url + '/sikkertspil/login?loginSuccessUrl=' + encodeURIComponent(returnUrl) + (options.cancelUrl ? '&cancelUrl=' + encodeURIComponent(options.cancelUrl) : '') + (options.variant ? '&variant=' + options.variant : '');
    } else {
      url = url + DS.Config.PRETTYPREFIX + '/login?loginSuccessUrl=' + encodeURIComponent(returnUrl) + (options.cancelUrl ? '&cancelUrl=' + encodeURIComponent(options.cancelUrl) : '') + (options.variant ? '&variant=' + options.variant : '');
    }

    // DsLogin doesnt support to be iframed (blocked by CSP), due to MitID rules. Therefore prefer to do on window.top
    try {
      window.top.location.href = url;
    } catch (e) {
      // No access to window.top, Sentry log and fallback
      ErrorLogging.capture('LoginController.openLogin: Could not redirect on window.top.location', { level: 'warning' });

      location.href = url;
    }
  };

  var getLogoutModeSetting = function () {
    const validOptions = ['classic', 'new_background', 'new_interactive'];
    return DS.Config.LOGOUT_MODE && validOptions.includes(DS.Config.LOGOUT_MODE) ? DS.Config.LOGOUT_MODE : 'classic';
  };

  var logout = function (options) {
    options = options || {};
    var redirectOnLogout = options.redirect !== 'undefined' ? options.redirect : true;

    var runLogoutCallbacks = function () {
      // This  tells all other open windows that the user has logged out
      CrossWindowEvents.fire('ds.event.userLoggedOut', { redirect: redirectOnLogout, context: DS.Config.CONTEXT });

      if (typeof options.callback === 'function') {
        options.callback();
      }
      UINewLogin.userIsLoggedOut(redirectOnLogout);

    };

    /* Logout on SitecoreAuth and old ClientAuth */
    SitecoreAuth.logout();

    /* Clear local cache */
    clearLocalCookies();

    /* Clear localStorage: */
    // this.clearLocalStorage();

    /* Logout on SSO */
    LoginSSO.logout(function () {
      console.debug('LoginSSO.logout callback');

      switch (getLogoutModeSetting()) {
      case 'new_background':
        LoginAdapter.backgroundLogout(runLogoutCallbacks);
        break;

      case 'classic':
      default:
        runLogoutCallbacks();
      }

    });

  };

  var refreshUserData = function (userObjGiven, forceUpdate, isInvokedFromEvent, callbackFunc) {
    forceUpdate = typeof forceUpdate != 'undefined' ? forceUpdate : false;

    if (userObjGiven && userObjGiven.customerInfo) {
      UINewLogin.userIsLoggedIn(userObjGiven);
      if (!isInvokedFromEvent) { // if function is invoked from an event, dont trigger another
        CrossWindowEvents.fire('ds.event.balanceUpdated', userObjGiven);
      }
    } else {
      SitecoreAuth.getUserObj(function (retrievedUserObj) {
        console.log('retrievedUserObj=' + retrievedUserObj);
        console.log(retrievedUserObj);
        if (retrievedUserObj) {
          UINewLogin.userIsLoggedIn(retrievedUserObj);
          CrossWindowEvents.fire('ds.event.getUserDataFromSitecore', retrievedUserObj);
          CrossWindowEvents.fire('ds.event.balanceUpdated', retrievedUserObj);
        }

        if (typeof callbackFunc === 'function') {
          callbackFunc();
        }

      }, forceUpdate);
    }
  };

  var getLoginStatus = function (callback) {
    if (LoginCache.isLoggedIn()) {
      // if logged in according to cache, response true
      callback(true);
    } else {
      // not logged in according to cache, ask server
      SitecoreAuth.getLoginStatus(function (loggedIn) {
        callback(loggedIn);
      });
    }
  };

  var clearLocalCookies = function (options) {
    var context = DS.Config.CONTEXT;
    if (options && typeof (options.context) === 'string') {
      context = options.context;
    }

    LoginCache.clearCache(context);

    Utils.cookie(DS.Config.CONTEXT + 'SitecoreIsLoggedIn', null);
  };

  var startRelayAndAuth = function (callback) {
    console.log('startRelayAndAuth()');
    callback = callback || function () { };

    LoginSSO.relay(function (json) {
      console.log('relay() done');
      console.log('assertionId=' + json.assertionId);
      if (json.assertionId) {
        SitecoreAuth.authWithAssertionId(json.assertionId, function (data) {
          if (data && data.loggedIn) {
            callback({ customerInfo: data.customerInfo });
          } else {
            logout();
          }
        });
      } else {

        /* Error: Not logged in on SSO */
        logout();
      }

    });
  };

  var getBossoHashCookieStatus = function () {
    var ssoHash = Utils.cookie('BOSSOisLoggedIn' + DS.Config.BOSSOCONTEXT);
    console.log('cookie BOSSOisLoggedIn' + DS.Config.BOSSOCONTEXT, ssoHash);
    var sitecoreHash = Utils.cookie(DS.Config.CONTEXT + 'SitecoreIsLoggedIn');
    console.log('cookie ' + DS.Config.CONTEXT + 'SitecoreIsLoggedIn', sitecoreHash);

    if (!ssoHash) {
      /* Not logged in on SSO */
      return !sitecoreHash ? 'OK_BOTH_LOGGED_OUT' : 'SSO_LOGGED_OUT_SITECORE_LOGGED_IN';

    } else {
      /* Logged in on SSO */
      if (!sitecoreHash) {
        return 'SSO_LOGGED_IN_SITECORE_LOGGED_OUT';
      } else if (sitecoreHash != ssoHash) {
        return 'SSO_LOGGED_IN_SITECORE_HASH_MISMATCH';
      } else {
        return 'OK_BOTH_LOGGED_IN';
      }
    }
  };

  var checkReloadOnLogin = function () {
    if (document.body.getAttribute('data-reloadonlogin') === 'true') {
      window.location.href = window.location.href.split('#')[0]; // reload page without #hash
      if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) { // if Safari..
        setTimeout(function () { // if the page has not reloaded after .1 sec
          window.location.reload(true); // reload, as Safari just treats the previous location.href as a hashchange
        }, 100);
      }
    }
  };

  var initOnDocumentReady = function () {

    var ready = function () {
      _synced = true;
      console.log('pageload-auth-synced');
      Event.fire('pageload-auth-synced');
    };

    var isContextNeutral = function () {
      return (location.pathname.length == 0) ? true : false;
    };

    if (isContextNeutral()) {
      return;
    }

    // Check for and handle indifferences on login state between SSO and SitecoreAuth
    var bossoCheck = getBossoHashCookieStatus();
    console.log('bossoHashCookieStatus: ' + bossoCheck);

    switch (bossoCheck) {
    case 'SSO_LOGGED_OUT_SITECORE_LOGGED_IN':
      logout({
        callback: ready
      });
      break;

    case 'SSO_LOGGED_IN_SITECORE_LOGGED_OUT':
    case 'SSO_LOGGED_IN_SITECORE_HASH_MISMATCH':
      clearLocalCookies();

      Utils.logDeprecated('LoginController.js - DsLogin enabled - SSO_LOGGED_IN_SITECORE_HASH_MISMATCH should not happen');

      // On dev, we must relay (until all is running through local nginx dev, see IU-17292)
      if (DS.Config.ENV === 'dev') {
        startRelayAndAuth(function (userObj) {
          LoginCache.refreshLoggedInLocalTime();
          UINewLogin.userIsLoggedIn(userObj);
          ready();
        });
      } else {
        refreshUserData(null, true, false, function () {
          CrossWindowEvents.fire('ds.event.userLoggedIn');
          ready();
        });
      }

      break;

    case 'OK_BOTH_LOGGED_IN':
      /* If no cached/broken userObj, OR If we come from another site, eg a DS mobile site, please refresh balance */
      var userObj = LoginCache.getUserObj();
      var externalRef = document.referrer && document.referrer.split('//')[1].split('/')[0].toLowerCase() != window.location.host.toLowerCase();

      if (!userObj || !userObj.customerInfo || externalRef) {
        console.debug('Logged in on SSO, but cached userObj is broken or come from outside, so fetch latest info from server');

        CrossWindowEvents.fire('ds.event.userLoggedIn');
        refreshUserData(null, true, false, function () {
          ready();
        });

      } else {
        /* Update the header according to the state in cache */
        UINewLogin.userIsLoggedIn(userObj);
        HeaderNotifications.gameScannerNotification(LoginCache.getUserObj());
        ready();
      }

      /* If logged in within 60s, show the previous login */
      if ((new Date()).getTime() - LoginCache.getLoggedInLocalTime() < 60000) {
        UINewLogin.showPreviousLogin();
      }

      break;

    case 'OK_BOTH_LOGGED_OUT':
      clearLocalCookies();
      ready();

    }

    // Attach DOM events
    document.querySelectorAll('.js-login-trigger').forEach(function ($element) {
      $element.addEventListener('click', function (e) {  // eslint-disable-line no-jquery/no-other-methods -- Not a jquery selector
        openLogin({
          source: 'loginTriggerButton'
        });

        e.preventDefault();
      });
    });

    document.querySelectorAll('.js-login-header').forEach(function ($element) {
      $element.addEventListener('click', function (e) { // eslint-disable-line no-jquery/no-other-methods -- Not a jquery selector
        e.preventDefault();

        const isOnSignUpPage = !!document.querySelector('[data-component="register"]');

        if (isOnSignUpPage) {
          // Tracking specific for when user is on the sign up page.
          Ensighten.pushGaEventOnPageRedirect('account_creation', 'login', 'header');
        }

        openLogin({
          source: 'headerLoginButton'
        });

      });
    });

    // Keep alive timer
    if (location.pathname !== '/') {
      KeepSessionAliveTimer.init(function () {
        SitecoreAuth.getUserObj(function (retrievedUserObj) {
          if (retrievedUserObj && typeof retrievedUserObj.customerInfo != 'undefined') {
            UINewLogin.userIsLoggedIn(retrievedUserObj);
            CrossWindowEvents.fire('ds.event.balanceUpdated', retrievedUserObj);
          } else {
            logout();
            KeepSessionAliveTimer.stopRefresh();
          }
        });
      }, logout, LoginCache.isLoggedIn);
    }

  };

  var init = function () {
    // Attach hashroute change #login
    // Must be defined before document.ready in order to be triggered onload
    HashChange.match(/^login/, function () {
      // Utils.logDeprecated('LoginController.js - init - HashChange#login triggered, hash: ' + hash); // Added by MIPE 2022-02-27 - Still used a lot from SDK login (sets app). More than 100k/day

      if (!LoginCache.isLoggedIn()) {
        openLogin({
          source: 'loginHash'
        });
      }
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedIn', function () {
      DS.apiParent.onUserLoggedIn(); // trig userLoggedIn event for game vendors
      var userObj = LoginCache.getUserObj();
      refreshUserData(userObj, false, true);
    });

    CrossWindowEvents.subscribe('ds.event.userLoggedOut', function (data) {
      DS.apiParent.onUserLoggedOut(); // tell DSAPI
      /* logoff all browser windows in dli or dlo out by deleting cookies and session storage og context when clicked log out */
      if (data.context) {
        clearLocalCookies(data.context);
      }
      UINewLogin.userIsLoggedOut(data.redirect);
      checkReloadOnLogin();
    });

    CrossWindowEvents.subscribe('ds.event.balanceUpdated', function (data) {
      if (data && (typeof (data.balance) === 'number')) { // if we have data
        UINewLogin.setBalances(data);
      } else {
        SitecoreAuth.getUserObj(function (data) {
          if (data && data.customerInfo && typeof (data.customerInfo.userBalance) === 'object') {
            var userBalance = data.customerInfo.userBalance;
            var balanceObj = {};

            if (userBalance.totalBalance != null) {
              balanceObj.balance = userBalance.totalBalance;
            }

            if (userBalance.availableBalance != null) {
              balanceObj.availableBalance = userBalance.availableBalance;
            }

            if (userBalance.restrictedBalance != null) {
              balanceObj.restrictedBalance = userBalance.restrictedBalance;
            }

            CrossWindowEvents.fire('ds.event.balanceUpdated', balanceObj);
          }
        }, data?.forceUpdate);
      }
    });

    if (/loaded|complete/.test(document.readyState)) {
      initOnDocumentReady();
    } else {
      document.addEventListener('DOMContentLoaded', function () {
        initOnDocumentReady();
      });
    }
  };


  // Scope:
  init();

  // public functions
  var publc = {
    isSynced: isSynced,
    getLoginStatus: getLoginStatus,
    logout: logout,
    openLogin: openLogin,
    refreshUserData: refreshUserData
  };
  return publc;
});
