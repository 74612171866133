defineDs('DanskeSpil/Domain/Feature.Navigation/Scripts/Components/ModalPopup', [
  'Shared/Framework/Ensighten/Scripts/Ensighten',
], function (Ensighten) {
  const modal = document.querySelector('.js-modal-popup');

  if (!modal) return;

  const buttonsContainer = modal.querySelector('.js-modal-popup-buttons');
  const modalInner = modal.querySelector('.js-modal-popup__inner');
  const modalCloseX = modal.querySelector('.js-modal-popup-close');

  let lastFocusedElement;
  const focusableElements = 'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])';

  const setFocus = (containerElement) => {
    // Find all focusable elements in the menu
    const firstFocusableElement = containerElement.querySelectorAll(focusableElements)[0];

    // Update tabindex to make them focusable
    containerElement.querySelectorAll(focusableElements)
      .forEach((f) => f.setAttribute('tabindex', '0'));

    // Set focus to the first focusable element
    setTimeout(() => {
      firstFocusableElement?.focus();
    }, 50);
  };

  const removeFocus = (containerElement) => {
    // Reset tabindex to make elements unfocusable again
    containerElement.querySelectorAll(focusableElements).forEach((f) => f.setAttribute('tabindex', '-1'));
  };

  const closeModal = () => {
    modal.classList.remove('ds-modal-popup--open');

    removeFocus(modal);
    document.dispatchEvent(new CustomEvent('overlay:modal:hide'));

    modal.ontransitionend = () => {
      lastFocusedElement?.focus();
      modal.ontransitionend = null;
    };
  };

  const resetModal = () => {
    modal.querySelector('.ds-modal-popup__icon')?.remove();
    modal.querySelectorAll('.ds-modal-popup__button').forEach((button) => button.remove());
  };

  const setupCta = ({ label, isPrimary = false, callback = null }, skipTracking = false) => {
    if (!label) return;

    const cta = document.createElement('a');
    cta.innerHTML = label;
    cta.classList.add('ds-modal-popup__button');

    if (isPrimary) {
      cta.classList.add('ds-modal-popup__button--primary');
    }

    if (!skipTracking) {
      cta.setAttribute('data-tracking-label', 'button: ' + cta.innerText);
    }

    // Clicking a button should close the modal, no matter what.
    cta.addEventListener('click', closeModal);

    if (callback) {
      // Add any additional custom callback.
      cta.addEventListener('click', callback);
    }

    buttonsContainer.appendChild(cta);

    if (!skipTracking) {
      Ensighten.addTrackingToFeatureModuleElement(cta);
    }
  };

  const openModal = ({ icon, title, text, buttons = [], hideCloseCta = false, hideCloseX = false, skipTracking = false }) => {
    // Remember where we came from, so that we can set focus on it when the modal closes.
    lastFocusedElement = document.activeElement;

    // Set the content of the modal.
    modal.querySelector('.js-modal-popup-title').innerHTML = title || '';
    modal.querySelector('.js-modal-popup-text').innerHTML = text || '';

    if (icon) {
      const img = document.createElement('img');
      img.classList.add('ds-modal-popup__icon');
      img.src = icon;
      modalInner.prepend(img);
    }

    if (!hideCloseCta) {
      buttons.push({ label: 'Luk' });
    }

    buttons.forEach((button) => {
      setupCta(button, skipTracking);
    });

    modal.classList.toggle('ds-modal-popup--hide-close-x', hideCloseX);

    modal.classList.add('ds-modal-popup--open');
    document.dispatchEvent(new CustomEvent('overlay:modal:show'));

    setFocus(modal);

    if (!skipTracking) {
      Ensighten.pushGaEvent('modal-popup', 'open', title);
    }
  };

  const initialize = () => {
    document.addEventListener('modal:open', ({ detail: { icon, title, text, buttons, hideCloseCta, hideCloseX, skipTracking } }) => {
      resetModal();
      openModal({ icon, title, text, buttons, hideCloseCta, hideCloseX, skipTracking });
    });

    document.addEventListener('modal:close', closeModal);

    modalCloseX.addEventListener('click', closeModal);
  };

  initialize();

});
